import React from "react";

import Define from "../../configurations/Define";
import { AsyncCallback } from "../../types/Callback";
import UserAuth from "../../services/UserAuth";
import User, { UserSign, UserSignSchema } from "../../types/User";
import { getErrorMessage } from "../../services/Rest";

export type SigninCallback = (result: boolean, user: User | null, reason?: string | null) => void;

/** ユーザーコンテキストモデル */
export type UserContextModel = {
    user: User | null;
    error: number;
    signin: (userSign: UserSign, callback?: SigninCallback) => void;
    signout: (userId: string, callback?: AsyncCallback) => void;
    updateOffice: (value: number, name: string, bizType: number, dataPreserve?: boolean, adlPersonalized?: boolean, availableReceipt?: boolean, physicalPlan?: boolean) => void;
    getAccessToken: (signal?: AbortSignal) => Promise<string | null>;
    errorMessage: string;
    setErrorMessage: (message: string) => void;
    clearError: () => void;
    getEffective: () => number;
};

/** ログインインプット */
export type SigninInput = UserSign;
export const SigninSchema = UserSignSchema;

/** ユーザーコンテキスト */
export const UserContext = React.createContext<UserContextModel>({ user: null } as UserContextModel);

/** ユーザーコンテキストフック */
export const useUser = () => React.useContext(UserContext);

/** ユーザープロバイダー */
const UserProvider = (props: { children: React.ReactNode }) => {
    const [user, setUser] = React.useState<User | null>(null);
    const [error, setError] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState("");

    const signin = (sign: UserSign, callback?: SigninCallback, signal?: AbortSignal) => {
        UserAuth.login(sign, signal).then(result => {         
            console.log(`get access token and refresh token.`);
            setUser(result);
            callback! && callback(true, result, "");
        }).catch(error => {
            if (error.response?.status !== 503) {
                console.error(error);
                getErrorMessage(error).then(msg => {
                    callback! && callback(false, null, msg.message);
                });
            }
        });
    };

    const signout = (userId: string, callback?: AsyncCallback) => UserAuth.logout(user!.accessToken, userId).then(() => {
        callback! && callback(true, "");
    }).catch(error => {
        console.error(error);
        getErrorMessage(error).then(msg => {
            callback! && callback(false, msg.message);
        });
    }).finally(() => {
        console.log(`release tokens.`);
        setUser(null);
    });
    
    const updateOffice = (value: number, name: string, bizType: number, dataPreserve?: boolean, adlPersonalized?: boolean, availableReceipt?: boolean, physicalPlan?: boolean) => {
        setUser({ ...user!, 
            office: value,
            officeName: name,
            bizType: bizType,
            dataPreserve: dataPreserve ?? false,
            adlPersonalized: adlPersonalized ?? false,
            availablePhysicalPlan: physicalPlan ?? false,

            availableReceipt: availableReceipt ?? false,
        });
    };

    const getAccessToken = async (signal?: AbortSignal) => {
        if (user!) {
            const now = (new Date()).getTime();
            const limit = user.loggedInAt.getTime() + (Define.accessableLimit - Define.accessableMargin);
            const refreshable = user.loggedInAt.getTime() + Define.expirationLimit;
            let message = "アクセス権限更新の有効期限を過ぎています。";
            if (now < refreshable) {
                try {
                    if (now > limit) {
                        const data = await UserAuth.refresh(user!.refreshToken, signal);
                        setUser({
                            ...user!, loggedInAt: new Date(),
                            //accessToken: data.idToken, refreshToken: data.refreshToken, awsToken: data.accessToken, 
                            accessToken: data.accessToken, refreshToken: data.refreshToken, awsToken: data.idToken, 
                        });
                        console.log(`updated access token from refresh token.`);
                        return data.idToken;
                    }
                    return user.accessToken;
                } catch {
                    message = "アクセス権限更新に失敗しました。";
                }                
            }
            setUser(null);
            setError(1);
            throw new Error(message);
        }        
        return null;
    };

    const clearError = () => setError(0);

    const getEffective = () => {
        let effective = user?.effective ?? 0;
        /** レセプト機能追加 */
        if (!(user?.availableReceipt ?? false)) {
            /** レセプト */
            if ((effective & 0x4000000) > 0) {
                effective -= 0x4000000;
            }
            /** レセプト口座管理 */
            
            /** 設定・レセプト */
            if ((effective & 0x10000000) > 0) {
                effective -= 0x10000000;
            }
        } 
        /** データ保持継続 */
        if (user?.dataPreserve ?? false) {
            /** 勤怠 */
            if ((effective & 0x2) > 0) {
                effective -= 0x2;
            }
            /** LIFE */
            if ((effective & 0x100) > 0) {
                effective -= 0x100;
            }
            /** 実施記録(帳票以外) */
            if ((effective & 0xd000) > 0) {
                effective -= 0xd000;
            }
            /** 設定 */
            if ((effective & 0xff0000) > 0) {
                effective -= 0xff0000;
            }
            /** サービス管理 */
            if ((effective & 0x3000000) > 0) {
                effective -= 0x3000000;
            }
        }
        return effective;
    };

    const model = { user, error, errorMessage, setErrorMessage, signin, signout, updateOffice, getAccessToken, clearError, getEffective };
    return <UserContext.Provider value={model}>{props.children}</UserContext.Provider>;
};
export default UserProvider;